import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Dev/Personal/kathryn-jones-nutrition/src/components/default-page-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Services`}</h1>
    <h2>{`Clinic (face to face) consults`}</h2>
    <p>{`Initial assessment (45-60mins) $140`}</p>
    <p>{`Review consult (20-30mins) $90 `}</p>
    <h2>{`Telehealth (phone) consults`}</h2>
    <p>{`Initial assessment (45-60mins) $90`}</p>
    <p>{`Review consult (20-30mins) $70 `}</p>
    <br />
    <ul>
      <li parentName="ul">{`Health fund rebates may be available`}</li>
      <li parentName="ul">{`Chronic disease management plans accepted`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      